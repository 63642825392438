<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Empresas</span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="m-20 float-right">
              <v-btn small color="white" :to="{ name: 'create-customer' }">
                <v-icon small dark> mdi-plus-circle </v-icon> Agregar empresa
              </v-btn></span
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <br />

        <v-data-table
          :headers="headers"
          :items="customers"
          :search="search"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :to="'/update-customer/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>
              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar la empresa?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      @click="
                        dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="3" class="pt-4 pb-4">
              Representante Legal:
              <strong> {{ item.legal_representative }}</strong
              ><br />
              Teléfono: <strong> {{ item.phone_legal_representative }}</strong
              ><br />
              Correo electrónico:
              <strong> {{ item.email_legal_representative }}</strong>
            </td>
            <td>
              Contador:
              <strong> {{ item.accountand }}</strong
              ><br />
              Teléfono: <strong> {{ item.phone_accountand }}</strong
              ><br />
              Correo electrónico:
              <strong> {{ item.email_accountand }}</strong>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    expanded: [],
    singleExpand: false,
    dialog: false,
    search: "",
    headers: [
      { text: "RUT", value: "rut" },
      { text: "Razón Social", value: "legal_name" },
      { text: "Nombre de Fantasía", value: "trade_name" },
      { text: "Correo Electrónico", value: "mail" },
      { text: "Teléfono", value: "phone" },
      { text: "Clave del S.I.I", value: "sii_key" },
      { text: "Acciones", value: "actions" },
    ],
    customers: [],
    plans: [],
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getCustomers();
  },
  watch: {},

  methods: {
    deleteItem(item) {
      console.log("eliminando");
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/destroy-customer", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          this.getCustomers();
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el cliente"
          );
          setTimeout(() => this.$router.push({ name: "customers" }), 4000);
        });
    },

    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Clientes");
          console.log(response.data);
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes"
          );
        });
    },
  },
};
</script>